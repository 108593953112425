<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div
            data-v-5cb60a6f=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cb60a6f=""
              class="m-2"
            >
              <div
                data-v-5cb60a6f=""
                class="row"
              >
                <div class="col-6">
<!--                  <input-->
<!--                    id="__BVID__1065"-->
<!--                    data-v-5cb60a6f=""-->
<!--                    type="text"-->
<!--                    placeholder="Search..."-->
<!--                    class="d-inline-block mr-1 form-control"-->
<!--                  >-->
                </div>
                <!--                <div class="col-3">-->
                <!--                  <router-link class="btn btn-primary" :to="{ name: 'apps-product-add'}"> Add Order</router-link>-->
                <!--                </div>-->
              </div>
            </div>
            <div
              data-v-5cb60a6f=""
              class="position-relative table-responsive"
            >
              <table
                id="__BVID__1070"
                role="table"
                aria-busy="false"
                aria-colcount="7"
                class="table b-table"
              ><!---->
                <!---->
                <thead
                  role="rowgroup"
                  class=""
                ><!---->
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                    >
                      <div>#</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="3"
                      aria-sort="none"
                      class=""
                    >
                      <div>Phone</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                    >
                      <div>Total Price</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Status</div>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                  <tr
                    v-for="(item,index) in listOrder"
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                  >
                    <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                    ><a
                      data-v-5cb60a6f=""
                      href="/apps/invoice/preview/5036"
                      class="font-weight-bold"
                      target="_self"
                    > {{ index + 1 }} </a></td>
                    <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                    >
                      <div
                        data-v-5cb60a6f=""
                        class="media"
                      >
                        <div
                          data-v-5cb60a6f=""
                          class="media-body"
                        ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                        > {{ item.customer.phone }} </span></div>
                      </div>
                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                    >
                      {{ convertCurrency(item.total) }}
                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                      v-html="getStatus(item.status)"
                    >
                    </td>

                  </tr>
                </tbody><!----></table>
            </div>
            <div
              data-v-5cb60a6f=""
              class="mx-2 mb-2"
            /><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'
import store from '@/store'
import useOrderList from './useOrderList'

import orderStoreModule from '../orderStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useOrderList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  data() {
    return {
      listOrder: [],
    }
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getMe({}).then(res => {
      console.log('get me', res.data)
      if (res.data.data.image == null){
        this.$store.commit('SHOW_UPDATE_BANNER', true)
      }
      if (res.data.data.time_open == null || res.data.data.time_close == null) {
        this.$store.commit('SHOW_UPDATE_TIME_OPEN', true)
      }
      sessionStorage.setItem('MERCHANT', JSON.stringify(res.data.data))
      this.getListOrder({}).then(res2 => {
        // console.log('RES getListOrder', res2)
        this.$store.commit('SHOW_LOADING', false)
        this.listOrder = res2.data.data
      }).catch(e2=> {
        this.$store.commit('SHOW_LOADING', false)
      })
    }).catch(e => {
      console.log(e)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListOrder']),
  },
  methods: {
    ...mapActions(['getListOrder','getMe']),
    getStatus(status) {
      if (status === 0) {
        return '<button class="btn btn-danger">DRAFT</button>'
      }
      if (status === 1) {
        return '<button class="btn btn-primary">CREATED</button>'
      }
      if (status === 2) {
        return '<button class="btn btn-success">CONFIRMED</button>'
      }
      if (status === 3) {
        return '<button class="btn btn-success">COMPLETED</button>'
      }
      if (status === 4) {
        return '<button class="btn btn-danger">CANCEL</button>'
      }
      if (status === 4) {
        return '<button class="btn btn-info">DELIVERING</button>'
      }
      if (status === 5) {
        return '<button class="btn btn-info">VOID</button>'
      }
    },
    convertCurrency(x){
      return x.toLocaleString('vi-VN') + ' VNĐ'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
